import React, { useState } from 'react';
import { Modal, Input, List, Button } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

const { Search } = Input;

function AddFriendModal({ visible, onClose }) {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (value) => {
    if (!value || value.length < 3) {
      toast.error('Please enter at least 3 characters to search');
      return;
    }
    setLoading(true);
    try {
      const response = await api.get(`/api/search-users?query=${value}`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching users:', error);
    }
    setLoading(false);
  };

  const sendFriendRequest = async (userId) => {
  try {
    const response = await api.post('/api/friends/request', { toUserId: userId });
    if (response.data === 'Friendship established') {
      toast.success('Friendship established successfully');
    } else if (response.data === 'Friend request sent') {
      toast.success('Friend request sent successfully');
    }

    setSearchResults(searchResults.filter(user => user.googleId !== userId));
  } catch (error) {
    toast.error(error.response.data);
    console.error('Error sending friend request:', error);
  }
};

  return (
    <Modal
      open={visible}
      title="Add a New Friend"
      onCancel={onClose}
      footer={null}
    >
      <Search
        placeholder="Search users"
        enterButton="Search"
        onSearch={handleSearch}
        loading={loading}
      />
      <List
        itemLayout="horizontal"
        dataSource={searchResults}
        renderItem={user => (
          <List.Item
            actions={[<Button type="primary" onClick={() => sendFriendRequest(user.googleId)}>Add Friend</Button>]}
          >
            <List.Item.Meta
              title={`${user.name} (${user.googleId.slice(0, 6)})`}
            />
          </List.Item>
        )}
      />
    </Modal>
  );
}

export default AddFriendModal;
