import React, { useEffect, useState } from 'react';
import { List, Typography, Rate, Avatar, Card, Image, Timeline, Row, Col } from 'antd';
import axios from 'axios';

const { Meta } = Card;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

function Feed() {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivities = async () => {
    try {
      const response = await api.get('/api/friends/activities');
      setActivities(response.data);
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };

  const items = activities.map((activity, index) => {
    return {
      children: 
        <Typography.Text>
            {activity.readDate.day.low}/{activity.readDate.month.low}/{activity.readDate.year.low} {activity.friend.name} read {activity.book.title}
        </Typography.Text>

    };
  });

  return (
    <div>
      <Typography.Title level={3}>Activity Feed</Typography.Title>
      <Row gutter={18}>
        <Col span={18}>
            <List
                grid={{ gutter: 18, column: 3 }}
                dataSource={activities}
                renderItem={activity => (
                <List.Item>
                    <Card
                    cover={<Image
                        alt={activity.book.title}
                        src={activity.book.cover}
                        style={{ borderRadius: 10, maxHeight: 420, objectFit: 'cover' }}
                        preview={false}
                    />}
                    actions={[
                        <Rate disabled defaultValue={activity.rating} />,
                        <Typography.Text>
                            {`${activity.readDate.day.low}/${activity.readDate.month.low}/${activity.readDate.year.low}`}
                        </Typography.Text>
                    ]}
                    >
                    <Meta
                        avatar={<Avatar
                        src={activity.friend.imageUrl}
                        size={{ xs: 24, sm: 32, md: 40 }}
                        />}
                        title={`${activity.friend.name} read ${activity.book.title}`}
                        description={`by ${activity.book.author}`}
                    />
                    </Card>
                </List.Item>
        )}
      />
      </Col>
      <Col span={6}>
          <Typography.Title level={4}>Timeline</Typography.Title>
          <Timeline items={items} mode='left' />
        </Col>
      </Row>
    </div>
  );
}

export default Feed;
