import React, { useEffect, useState } from 'react';
import { List, Typography, Badge } from 'antd';
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

function Achievements() {
  const [achievements, setAchievements] = useState([]);

  useEffect(() => {
    fetchAchievements();
  }, []);

  const fetchAchievements = async () => {
    try {
      const response = await api.get('/api/achievements');
      setAchievements(response.data);
    } catch (error) {
      console.error('Error fetching achievements:', error);
    }
  };

  return (
    <div>
      <Typography.Title level={3}>Achievements</Typography.Title>
      <List
        itemLayout="horizontal"
        dataSource={achievements}
        renderItem={achievement => (
          <List.Item>
            <List.Item.Meta
              title={<Badge count={1} style={{ backgroundColor: '#52c41a' }}>{achievement.title}</Badge>}
              description={achievement.description}
            />
          </List.Item>
        )}
      />
    </div>
  );
}

export default Achievements;
