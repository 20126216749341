import React, { useState, useEffect } from 'react';
import { List, Button, Typography } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

function FriendRequests() {
  const [friendRequests, setFriendRequests] = useState([]);

  useEffect(() => {
    fetchFriendRequests();
  }, []);

  const fetchFriendRequests = async () => {
    try {
      const response = await api.get('/api/friend-requests');
      setFriendRequests(response.data);
    } catch (error) {
      console.error('Error fetching friend requests:', error);
    }
  };

  const acceptFriendRequest = async (fromUserId) => {
    try {
      await api.post('/api/friends/accept', { fromUserId });
      fetchFriendRequests();
      toast.success('Friend request accepted!');
    } catch (error) {
      console.error('Error accepting friend request:', error);
    }
  };

  const declineFriendRequest = async (fromUserId) => {
    try {
      await api.post('/api/friends/decline', { fromUserId });
      fetchFriendRequests();
      toast.success('Friend request declined!');
    } catch (error) {
      console.error('Error declining friend request:', error);
    }
  }

  return (
    <div>
      <Typography.Title level={4}>Friend Requests</Typography.Title>
      <List
        dataSource={friendRequests}
        renderItem={request => (
          <List.Item>
            <Typography.Text>{request.name}</Typography.Text>
            <Button type="default" onClick={() => acceptFriendRequest(request.googleId)} color='black'>Accept</Button>
            <Button type="default" onClick={() => declineFriendRequest(request.googleId)} color='red'>Decline</Button>
          </List.Item>
        )}
      />
    </div>
  );
}

export default FriendRequests;
