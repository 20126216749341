import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Button, Typography, Layout, Image, Dropdown, Menu, Flex, Input, Badge, Progress } from 'antd';
import { ReadOutlined, HomeOutlined, ProductOutlined, 
          PlusCircleOutlined, UserAddOutlined, UsergroupAddOutlined,
          CoffeeOutlined, TrophyOutlined, LineChartOutlined  } from '@ant-design/icons';
import BookCard from './BookCard';
import AddBookDrawer from './AddBookDrawer';
import Recommendations from './Recommendations';
import FriendRequests from './FriendRequests';
import FriendsList from './FriendsList';
import Feed from './Feed';
import Profile from './Profile';
import Achievements from './Achievements';
import { GlobalContext } from '../App';


const { Header, Sider, Content } = Layout;
const { Search } = Input;

const headerStyle = {
  textAlign: 'center',
  color: '#4B2E2A',
  height: 64,
  paddingInline: 48,
  paddingLeft: '24px',
  paddingRight: '1rem',
  lineHeight: '64px',
  backgroundColor: 'rgba(250, 251, 251)',
  borderBottom: '1px solid #f0f0f0',
};

const contentStyle = {
  textAlign: 'center',
  minHeight: 'calc(100vh - 128px)',
  lineHeight: '3.5rem',
  color: '#6B4F4B',
  backgroundColor: '#FFF',
  padding: '1rem',
};
const siderStyle = {
  textAlign: 'left',
  lineHeight: '3.5rem',
  color: '#4B2E2A',
  backgroundColor: 'rgba(250, 251, 251)',
  borderRight: '1px solid #f0f0f0',
};

const layoutStyle = {
  overflow: 'hidden',
  width: '100%',
  maxWidth: '100%',
  padding: "0px"
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

function Dashboard() {
  const [user, setUser] = useState(null);
  const [books, setBooks] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [activeMenu, setActiveMenu] = useState('myBooks');
  const [searchQuery, setSearchQuery] = useState('');
  const [friendRequestsCount, setFriendRequestsCount] = useState(0);
  const [goal, setGoal] = useState(null);
  // const [currentProgress, setCurrentProgress] = useState(0);
  const { currentProgress, setCurrentProgress } = useContext(GlobalContext);

  useEffect(() => {
    api.get('/api/profile')
      .then(response => setUser(response.data))
      .catch(error => console.error(error));
  }, []);

  const fetchBooks = () => {
    api.get('/api/books')
      .then(response => setBooks(response.data))
      .catch(error => console.error(error));
  };

  const fetchProgress = async () => {
    try {
      const response = await api.get('/api/reading-progress');
      if (response.data) {
        setGoal(response.data.goal);
        setCurrentProgress(response.data.currentProgress.low);
      }
    } catch (error) {
      console.error('Error fetching reading progress:', error);
    }
  };

  const fetchFriendRequestsCount = () => {
    api.get('/api/friend-requests/count')
      .then(response => setFriendRequestsCount(response.data.count))
      .catch(error => console.error(error));
  };

  useEffect(() => {
    fetchBooks();
    fetchFriendRequestsCount();
    fetchProgress();
  }, []);

  const handleDeleteBook = (key) => {
    setBooks(books.filter(book => book.key !== key));
  };

  const handleBookAdded = () => {
    fetchBooks();
    fetchProgress();
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const filteredBooks = books.filter(book =>
    book.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    book.author.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (!user) {
    return <div>Loading...</div>;
  }

  const menuItems = [
    { key: 'myBooks', icon: <HomeOutlined />, label: 'My Books' },
    { key: 'recommendations', icon: <ProductOutlined />, label: 'Recommendations' },
    { key: 'friends', icon: <UsergroupAddOutlined />, label: 'Friends' },
    {
      key: 'friendRequests',
      icon: <UserAddOutlined />,
      label: (
        <Badge count={friendRequestsCount} offset={[10, 0]}>
          Friend Requests
        </Badge>
      )
    },
    { key: 'feed', icon: <CoffeeOutlined />, label: 'Feed' },
    { key: 'achievements', icon: <TrophyOutlined />, label: 'Achievements' }
  ];

  const progressPercent = goal ? (currentProgress / goal) * 100 : 0;

  return (
    <Layout style={layoutStyle}>
      <Header style={headerStyle}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography.Title level={3} style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
            <ReadOutlined style={{ marginRight: '0.5rem' }} /> Readi
          </Typography.Title>
          <Search
              placeholder="Search in your books"
              onSearch={handleSearch}
              onChange={e => setSearchQuery(e.target.value)}
              style={{ width: 300, marginLeft: '2rem' }}
            />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography.Text style={{ marginRight: '1rem', fontSize: '0.8rem' }}>
              Current Goal Progress:
            </Typography.Text>
            <div style={{ marginLeft: '1rem'}}>
              <Progress size="small" percent={progressPercent} status="active" type='dashboard' />
            </div>
          </div>
          <Dropdown
            menu={{
              items: [
                {
                  label: `Google ID: ${user.properties.googleId}`,
                  disabled: true,
                },
                {
                  type: 'divider',
                },
                {
                  label: 'Profile',
                  onClick: () => setActiveMenu('profile'),
                },
                {
                  label: 'Sign out',
                  onClick: () => {
                    api.get('/api/logout')
                      .then(() => window.location = '/')
                      .catch(error => console.error(error));
                  }
                }
              ]
            }}
            trigger={['click']}
            >
            <a onClick={e => e.preventDefault()}>
              <div>
                <Typography.Text 
                  style={{ marginRight: '0.5rem' }}
                >
                  {user.properties.name}
                </Typography.Text>
                <Image src={user.properties.imageUrl} 
                      style={{ borderRadius: '10%' }} 
                      width={25} height={25}
                      preview={false} />
              </div>
            </a>
          </Dropdown>
        </div>
      </Header>
      <Layout>
        <Sider width="15%" style={siderStyle}>
          <Menu
            mode="inline"
            selectedKeys={[activeMenu]}
            onClick={({ key }) => setActiveMenu(key)}
            items={menuItems}
            style={{ borderRight: 'none' }}
          />
        </Sider>
        <Content style={contentStyle}>
          {activeMenu === 'myBooks' && (
            <>
            <Flex style={
              { justifyContent: 'space-between', alignItems: 'center' }
            }>
              <Typography.Title level={3}>Books</Typography.Title>
              <div>
                <Button onClick={showDrawer} style={{ marginBottom: 16 }} color='black' icon={<PlusCircleOutlined />}>
                  Add Book
                </Button>
              <AddBookDrawer visible={drawerVisible} onClose={onClose} onBookAdded={handleBookAdded} />
              </div>
            </Flex>
            <Flex wrap gap={25}>
              {filteredBooks.map(book => (
                <div key={book.key}>
                  <BookCard book={book} onDelete={handleDeleteBook} />
                </div>
              ))}
            </Flex>
            </>
          )}
          {activeMenu === 'recommendations' && (
            <div>
              <Flex style={
              { justifyContent: 'space-between', alignItems: 'center' }
              }>
              <Typography.Title level={3}>Recommendations</Typography.Title>
            </Flex>
              <Recommendations refreshKey={books.length} />
            </div>
          )}
          {activeMenu === 'friendRequests' && (
            <div>
              <FriendRequests />
            </div>
          )}
          {activeMenu === 'friends' && (
            <div>
              <FriendsList />
            </div>
          )}
          {activeMenu === 'feed' && (
            <div>
              <Feed />
            </div>
          )}
          {activeMenu === 'profile' && (
            <div>
              <Profile />
            </div>
          )}
          {activeMenu === 'achievements' && (
            <div>
              <Achievements />
            </div>
          )}
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
