import React, { useState, useEffect } from 'react';
import { List, Typography, Rate, Button } from 'antd';
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

function FriendBooks({ friendId, friendName, onBack }) {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    fetchFriendBooks();
  }, []);

  const fetchFriendBooks = async () => {
    try {
      const response = await api.get(`/api/friends/${friendId}/books`);
      setBooks(response.data);
    } catch (error) {
      console.error('Error fetching friend\'s books:', error);
    }
  };

  console.log(books)

  return (
    <div>
      <Typography.Title level={4}>{friendName}'s Books</Typography.Title>
      <Button type="link" onClick={onBack}>Back to Friends</Button>
      <List
        itemLayout="horizontal"
        dataSource={books}
        renderItem={book => (
          <List.Item>
            <List.Item.Meta
              title={book.title}
              description={`Author: ${book.author}, Year: ${book.year}`}
            />
            <div>
              <Rate disabled defaultValue={book.rating} />
              <div>{`${book.readDate.day.low}/${book.readDate.month.low}/${book.readDate.year.low}`}</div>
            </div>
          </List.Item>
        )}
      />
    </div>
  );
}

export default FriendBooks;
