import React, { useEffect, useState } from 'react';
import { Progress, Typography, message } from 'antd';
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

function ProgressTracker() {
  const [goal, setGoal] = useState(null);
  const [currentProgress, setCurrentProgress] = useState(0);

  const fetchProgress = async () => {
    try {
      const response = await api.get('/api/reading-progress');
      if (response.data) {
        setGoal(response.data.goal);
        setCurrentProgress(response.data.currentProgress.low);
      }
    } catch (error) {
      console.error('Error fetching reading progress:', error);
      message.error('Error fetching reading progress');
    }
  };

  useEffect(() => {
    fetchProgress();
  }, []);

  const progressPercent = goal ? (currentProgress / goal) * 100 : 0;

  return (
    <div>
      <Typography.Title level={3}>Reading Progress</Typography.Title>
      {goal ? (
        <Progress percent={progressPercent} />
      ) : (
        <Typography.Text>No reading goal set</Typography.Text>
      )}
    </div>
  );
}

export default ProgressTracker;
