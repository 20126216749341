import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Layout, Card, Button, Typography } from 'antd';
import { GoogleOutlined, ReadOutlined } from '@ant-design/icons';
import axios from 'axios';
import Dashboard from './components/Dashboard';
import { H } from 'highlight.run';
import { ErrorBoundary } from '@highlight-run/react';

H.init('7e38r5qd', {
	serviceName: "readi.site",
	tracingOrigins: true,
	networkRecording: {
		enabled: true,
		recordHeadersAndBody: true,
		urlBlocklist: [
			// insert full or partial urls that you don't want to record here
		],
	},
});

const { Content } = Layout;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

export const GlobalContext = React.createContext();

function App() {
  const [currentProgress, setCurrentProgress] = useState(0);
  return (
    <GlobalContext.Provider value={{ currentProgress, setCurrentProgress }}>
      <Router>
        <ErrorBoundary>
        <Layout style={{ minHeight: '100vh' }}>
          <Content style={contentStyle}>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            </Routes>
          </Content>
        </Layout>
        </ErrorBoundary>
      </Router>
      </GlobalContext.Provider>
  );
}

const loginWithGoogle = () => {
  window.location.href = `${process.env.REACT_APP_API_BASE_URL}/api/auth/google`;
};

function LoginPage() {
  return (
    <div style={loginContainerStyle}>
      <Card style={cardStyle}>
        <ReadOutlined style={{ fontSize: '4rem' }} />
        <div style={{ marginBottom: '2rem' }}>
        <Typography.Title level={2} style={{ marginBottom: '1rem' }}>
          Welcome to Readi
        </Typography.Title>
        <Typography.Text style={{ marginBottom: '1rem' }} type="secondary">
          Please login to continue
        </Typography.Text>
        </div>
        <Button type="primary" onClick={loginWithGoogle} icon={<GoogleOutlined />} style={{ width: '100%' }}>
          Login with Google
        </Button>
      </Card>
    </div>
  );
}

function PrivateRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get('/api/profile')
      .then(response => {
        setIsAuthenticated(true);
        setLoading(false);
        H.identify(response.data.properties.googleId, {
          name: response.data.properties.name,
        });
      })
      .catch(error => {
        setIsAuthenticated(false);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to="/" />;
}

const contentStyle = {
  // display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // padding: '2rem',
  backgroundColor: '#FFF',
};

const loginContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
};

const cardStyle = {
  width: 400,
  textAlign: 'center',
  padding: '1rem',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
};

export default App;
