import React, { useEffect, useState } from 'react';
import { InputNumber, Button, message, Alert } from 'antd';
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

function SetGoal({ onGoalSet }) {
  const [goal, setGoal] = useState(null);
  const [currentGoal, setCurrentGoal] = useState(null);

  const handleSetGoal = async () => {
    try {
      await api.post('/api/reading-goal', { goal });
      message.success('Reading goal set successfully');
      onGoalSet();
    } catch (error) {
      console.error('Error setting reading goal:', error);
      message.error('Error setting reading goal');
    }
  };

  const fetchCurrentGoal = async () => {
    try {
      const response = await api.get('/api/reading-goal');
      setCurrentGoal(response.data.goal);
    } catch (error) {
      console.error('Error fetching reading goal:', error);
      message.error('Error fetching reading goal');
    }
  }

  useEffect(() => {
    fetchCurrentGoal();
  }
  , []);


  return (
    <div>
      {currentGoal != null && (
        <Alert message={`Your current goal is ${currentGoal} books. If you set a new goal, your progress will be reset.`} type="info" showIcon style={{ marginBottom: '1rem' }} />
      )}
      <InputNumber min={1} value={goal} onChange={setGoal} />
      <Button type="primary" onClick={handleSetGoal} style={{ marginLeft: '1rem' }}>
        Set Goal
      </Button>
    </div>
  );
}

export default SetGoal;
