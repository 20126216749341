import React, { useState, useEffect } from 'react';
import { List, Typography, Button } from 'antd';
import axios from 'axios';
import AddFriendModal from './AddFriendModal';
import FriendBooks from './FriendBooks';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

function FriendsList() {
  const [friends, setFriends] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState(null);

  useEffect(() => {
    fetchFriends();
  }, []);

  const fetchFriends = async () => {
    try {
      const response = await api.get('/api/friends');
      setFriends(response.data);
    } catch (error) {
      console.error('Error fetching friends:', error);
    }
  };

  const handleDeleteFriend = (friendId) => {
    console.log('Removing friend:', friendId);
    api.delete(`/api/friends/remove/${friendId}`)
        .then(response => {
            console.log('Friend removed:', response.data);
            fetchFriends();
        })
        .catch(error => console.error('Error removing friend:', error));
    };

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const selectFriend = (friend) => {
    setSelectedFriend(friend);
  };

  const handleBack = () => {
    setSelectedFriend(null);
  };

  if (selectedFriend) {
    return (
      <FriendBooks friendId={selectedFriend.googleId} friendName={selectedFriend.name} onBack={handleBack} />
    );
  }

  return (
    <div>
      <Typography.Title level={4}>My Friends</Typography.Title>
      <Button type="default" onClick={showModal} style={{ marginBottom: '1rem' }} color='black'>
        Add Friend
      </Button>
      <AddFriendModal visible={modalVisible} onClose={closeModal} />
      <List
        dataSource={friends}
        renderItem={friend => (
          <List.Item>
            <List.Item onClick={() => selectFriend(friend)} style={{ cursor: 'pointer' }}>
                <Typography.Text>{friend.name}</Typography.Text>
            </List.Item>
            <Button type="default" color='red' onClick={() => handleDeleteFriend(friend.googleId)}>Remove</Button>
          </List.Item>
        )}
      />
    </div>
  );
}

export default FriendsList;
