import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Flex, Spin, Image, Rate } from 'antd';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

const { Meta } = Card;

const Recommendations = ( {refreshKey} ) => {
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const response = await api.get('/api/recommendations');
        console.log('Recommendations:', response.data);
        setRecommendations(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching recommendations:', error);
        setLoading(false);
      }
    };

    fetchRecommendations();
  }, [refreshKey]);

  return (
    <div>
      {loading ? (
        <Spin />
      ) : (
        <Flex wrap gap={25}>
          {recommendations.map((rec, index) => (
              <Card
                  // hoverable
                  style={{ width: 180, border: 'none', textAlign: 'center' }} // Remove outline and center content
                  cover={
                    <Image
                      alt={rec.title}
                      src={rec.cover}
                      style={{ borderRadius: 10, height: 240 }}
                      preview={false}
                    />
                  }
                >
                <Rate disabled value={rec.ol_rating} style={{ marginBottom: 10 }} /> {/* Display star ratings */}
                <Meta
                  title={rec.title}
                  description={(
                    <>
                      <span>{rec.author}</span>
                      <br />
                      <span>{rec.year}</span>
                    </>
                  )}
                />
              </Card>
          ))}
        </Flex>
      )}
    </div>
  );
};

export default Recommendations;
