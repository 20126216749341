import React, { useState } from 'react';
import axios from 'axios';
import { Drawer, Button, Form, Input, AutoComplete, Rate, Spin, Image } from 'antd';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';

const { Option } = AutoComplete;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

const AddBookDrawer = ({ visible, onClose, onBookAdded }) => {
  const [form] = Form.useForm();
  const [options, setOptions] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const [loading, setLoading] = useState(false);

const fetchBookSuggestions = debounce(async (value) => {
  if (value.length < 3) return;
  try {
    // Format value for search query
    value = value.replace(/[^a-zA-Z0-9 ]/g, '').replace(/ /g, '+');
    value = value.toLowerCase();
    const response = await api.get(`/api/openlibrary?q=${value}`, { withCredentials: true });
    const books = response.data.docs.slice(0, 5);
    const suggestions = books.map(book => ({
      key: `${book.title}-${book.author_name ? book.author_name[0] : 'Unknown Author'}-${book.first_publish_year || 'Unknown Year'}`,
      title: book.title,
      author: book.author_name ? book.author_name.join(', ') : 'Unknown Author',
      year: book.first_publish_year || 'Unknown Year',
      isbn: book.isbn,
      cover: book.isbn ? `https://covers.openlibrary.org/b/isbn/${book.isbn[book.isbn.length - 1]}-M.jpg` : 'https://via.placeholder.com/240',
      subjects: book.subject ? book.subject : [],
      ol_rating: book.ratings_average ? book.ratings_average : null,
    }));
    setOptions(suggestions);
  } catch (error) {
    console.error('Error fetching book suggestions:', error);
  }
}, 250); // 1 second delay

  const getCoverUrl = async (isbns) => {
    for (let i = isbns.length - 1; i >= 0; i--) {
      if (isbns[i][3] === '0' && isbns[i].length === 13) {
        const coverUrl = `https://covers.openlibrary.org/b/isbn/${isbns[i]}-M.jpg?default=false`;
        if (await checkImageExists(coverUrl)) {
          return coverUrl;
            }
            }
        }
      return 'https://via.placeholder.com/240';
    };

  const checkImageExists = async (url) => {
    try {
        const response = await fetch(url, { method: 'HEAD' });
        return response.ok;
    } catch (error) {
        console.error('Error checking image:', error);
        return false;
    }};

  const handleSelect = async (key) => {
    setLoading(true);
    const selected = options.find(book => book.key === key);
    if (selected.isbn) {
      selected.cover = await getCoverUrl(selected.isbn);
    }
    setSelectedBook(selected);
    setLoading(false);
    form.setFieldsValue({
      title: selected.title,
      author: selected.author,
      year: selected.year,
      ol_rating: selected.ol_rating ? selected.ol_rating : null,
    });
  };

  const handleSearch = (value) => {
    fetchBookSuggestions(value);
  };

  const handleSubmit = (values) => {
    console.log('Adding book:', selectedBook );
    api.post('/api/books', 
        {   title: selectedBook.title, rating: values.rating, cover: selectedBook.cover, 
            author: selectedBook.author, year: selectedBook.year, 
            key: selectedBook.key, subjects: selectedBook.subjects,
            ol_rating: selectedBook.ol_rating }, 
            { withCredentials: true })
      .then(response => {
        form.resetFields();
        onBookAdded();
        onClose();
        console.log('Book added:', response.data);
        toast.success('Book added successfully!');
      })
      .catch(error => console.error(error));
  };

  return (
    <Drawer
      title="Add a New Book"
      width={400}
      onClose={onClose}
      open={visible}
      body={{ paddingBottom: 80 }}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="title"
          label="Book Title"
          rules={[{ required: true, message: 'Please enter the book title' }]}
        >
          <AutoComplete onSearch={handleSearch} onSelect={handleSelect}>
            {options.map((option) => (
            <Option key={option.key + Math.random()} value={option.key}>
                {option.title} - {option.author} ({option.year})
            </Option>
            ))}
          </AutoComplete>
        </Form.Item>

        {selectedBook && (
          <div style={{ textAlign: 'center', paddingBottom: '1rem' }}>
            <Spin spinning={loading}>
              <Image alt={selectedBook.title} src={selectedBook.cover} style={{ borderRadius: 10, height: 240 }} preview={false} />
            </Spin>
          </div>
        )}

        <Form.Item
          name="rating"
          label="Your Rating"
          rules={[{ required: true, message: 'Please enter your rating' }]}
        >
          <Rate />
        </Form.Item>

        <Form.Item name="author" label="Author">
          <Input disabled />
        </Form.Item>

        <Form.Item name="year" label="Year">
          <Input disabled />
        </Form.Item>

        <Form.Item name="ol_rating" label="Open Library Rating">
          <Input disabled />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Add Book
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddBookDrawer;
