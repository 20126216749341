import React, { useEffect, useState } from 'react';
import { Descriptions, Avatar, Typography, Spin, message, Divider } from 'antd';
import axios from 'axios';
import SetGoal from './SetGoal';
import ProgressTracker from './ProgressTracker';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

function Profile() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [goal, setGoal] = useState(null);
  const [currentProgress, setCurrentProgress] = useState(0);

  useEffect(() => {
    fetchUserProfile();
    fetchProgress();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await api.get('/api/profile-details');
      setUser(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      message.error('Error fetching profile details');
      setLoading(false);
    }
  };

  const fetchProgress = async () => {
    try {
      const response = await api.get('/api/reading-progress');
      if (response.data) {
        setGoal(response.data.goal);
        setCurrentProgress(response.data.currentProgress.low);
      }
    } catch (error) {
      console.error('Error fetching reading progress:', error);
      message.error('Error fetching reading progress');
    }
  };

  const handleGoalSet = () => {
    fetchProgress();
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <div>
      <Typography.Title level={3}>Profile</Typography.Title>
      <Avatar src={user.imageUrl} size={100} />
      <Descriptions title="User Info" bordered={false}>
        <Descriptions.Item label="Name">{user.name}</Descriptions.Item>
        <Descriptions.Item label="GoogleID">{user.googleId}</Descriptions.Item>
        <Descriptions.Item label="Books Read">{user.booksRead}</Descriptions.Item>
        <Descriptions.Item label="Friends">{user.friends}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <div style={{ marginTop: '2rem' }}>
        <Typography.Title level={4}>Set Reading Goal</Typography.Title>
        <SetGoal onGoalSet={handleGoalSet} />
      </div>
      <div style={{ marginTop: '2rem' }}>
        <ProgressTracker />
      </div>
    </div>
  );
}

export default Profile;
