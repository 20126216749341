import React from 'react';
import { Card, Button, Image, Rate } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

const { Meta } = Card;

function BookCard({ book, onDelete }) {
  const handleDelete = () => {
    api.delete(`/api/books/${book.key}`)
      .then(response => {
        console.log('Book deleted:', response.data);
        toast.success('Book deleted successfully');
        onDelete(book.key);
      })
      .catch(error => console.error('Error deleting book:', error));
  };

  let cover = book.cover;
  if (!cover) {
    cover = 'https://via.placeholder.com/240x320?text=No+Cover';
  }


  return (
    <Card
      // hoverable
      style={{ width: 180, border: 'none', textAlign: 'center' }} // Remove outline and center content
      cover={
        <Image
          alt={book.title}
          src={book.cover}
          style={{ borderRadius: 10, height: 240, objectFit: 'cover' }}
          preview={false}
        />
      }
      actions={[
        <Button type="default" onClick={handleDelete} icon={<DeleteOutlined />}>Delete</Button>
      ]}
    >
      <Rate disabled value={book.rating} style={{ marginBottom: 10 }} /> {/* Display star ratings */}
      <Meta
        title={book.title}
        description={(
          <>
            <span>{book.author.split(",")[0]}</span>
            <br />
            <span>{book.year}</span>
          </>
        )}
      />
    </Card>
  );
};

export default BookCard;
